@import './_styles';

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100% !important;
  height: 100% !important;
}

.login__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login__logo {
  background-image: url('./components/images/logo.png');
  background-size: 96px 96px;
  background-repeat: no-repeat;
  width: 96px;
  height: 96px;
  padding-bottom: 60px;
  border-radius: 16px;
}

.login__spacer {
  height: 200px;
}

.App {
  text-align: center;
}
